$('body.rdr.show.serials').each(() => {
    $('.btnDeleteSerials').on('click', async function () {
        const btn = $(this);
        const deleteUrl = btn.data('url');
        var isLast = false;

        if ($('.btnDeleteSerials').length == 1) {
            isLast = true;
        }

        // TODO - Use a fancy and beautiful modal
        if (isLast) {
            var res = window.confirm("ATTENZIONE. Eliminando questo seriale la richiesta di ritiro verrà cancellata. Si vuole proseguire?");
        } else {
            var res = window.confirm("ATTENZIONE. Eliminare il seriale dalla Richiesta di Ritiro?\nL'operazione è irreversibile.");
        }
        if (res) {
            $(this).hide();
            $(this).next().show();
            const result = await axios.delete(deleteUrl);
            if (result.data.result === 'OK') {
                window.location.reload();
            } else {
                window.location.replace(result.data.result)
            }
        }
    });
});


$('body.rdr.create.campaign').each(() => {


    var campagna = undefined;
    var campaignKey = "";

    let cont = 0;
    let peso = 0;
    let colli = 0;
    var vins = [];

    let input = document.querySelector('input[name=seriali]')
    // init Tagify script on the above inputs
    let tagify = new Tagify(input, {})


    // comportamento checkbox rdr in campagna senza codice azione
    $('#no_key_campaign').click(function () {
        if ($(this).prop("checked") && $("#campaign_key").val() == "") {
            $("#campaign_key").attr("disabled", "disabled");
            $('.input-serials-card').show();
            $('#vinInputContainer').show();
            $('#pesoInputContainer').show();
            $('#serialiInputContainer').show();
            campagna = undefined;
            campaignKey = "";
        } else if ($(this).prop("checked") && $("#campaign_key").val() != "") {
            alert('Se si vuole aprire una richiesta di ritiro in campagna senza codice azione rimuovere lo stesso.')
            $(this).prop("checked", false);
        } else if ($(this).prop("checked", false)) {
            $('.input-serials-card').hide();
            $('#vinInputContainer').hide();
            $('#pesoInputContainer').hide();
            $('#serialiInputContainer').hide();
            $("#campaign_key").removeAttr("disabled");

        }
    })

    //  blocco il funzionamento dell'enter sulla form
    $('form input').keydown(function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            return false;
        }
    });


    $("#btn-check-campaign").click(async function () {
        campaignKey = $("#campaign_key").val();
        var cerId = $("#cer_id").val();
        if (campaignKey == "") {
            return alert("Chiave campagna o codice azione richiesto");
        }
        /* Recupero la campagna */
        if (campaignKey != "") {
            // console.log(APPURL + "/api/campagna/key/" + campaignKey);
            campagna = await fetchData(APPURL + "/api/campagna/key/" + campaignKey,
                optionsHeader)
            /* Verifico se la campagna è valida */
            if (!campagna || !campagna.data || !campagna.data.anagrafica_id) {
                campagna = undefined;
                alert(" Spiacente, campagna non trovata");
            } else {
                campagna = campagna.data;
                if (!campagna.attivo) {
                    campagna = undefined;
                    alert("Campagna non attiva");
                } else if (campagna.start_date != null && new Date(campagna.start_date + " 0:0:0") >
                    new Date()) {
                    campagna = undefined;
                    alert("Campagna non iniziata");
                } else if (campagna.end_date != null && new Date(campagna.end_date + " 24:0:0") <
                    new Date()) {
                    campagna = undefined;
                    alert("Campagna scaduta");
                } else if (campagna.cer_id != cerId) {
                    campagna = undefined;
                    alert("Campagna non attiva per questo codice CER");
                } else if (campagna.anagrafica_id != $('#principale_id').val()) {
                    campagna = undefined;
                    alert("Campagna non disponibile per la società selezionata");
                }
            }
        }

        /* in base al tipo di campagna mostro gli input necessari alla compilazione */
        if (campagna) {
            // mi salvo il tipo di campagna
            var campagnaType = campagna.tipologia_campagna;

            var inputVin = $('#vinInputContainer');
            var inputPeso = $('#pesoInputContainer');
            var inputSeriali = $('#serialiInputContainer');


            switch (campagna.tipologia_campagna) {
                case 0: case 1:
                    console.log('ford,inverter');

                    $('.input-serials-card').show();
                    inputVin.show();
                    inputPeso.hide();
                    inputSeriali.show();

                    break;

                case 2:
                    console.log('mercedes');

                    $('.input-serials-card').show();
                    inputVin.show();
                    inputPeso.hide();
                    inputSeriali.hide();

                default:
                    break;
            }

        } else {
            $('.input-serials-card').hide();
        }
    });

    $('#btn-add-serials').click(async function () {
        $('#btn-add-serials').attr('disabled', true);

        if (campagna) {
            switch (campagna.tipologia_campagna) {
                // vin only
                case 0: case 1:
                    var vinVal = $('#serial').val();
                    var pesoVal = $('#peso_seriale').val();
                    var serialiVal = $('#seriali').val();

                    // verifico che i campi siano compilati
                    if (vinVal.length == 0) {
                        $('#btn-add-serials').attr('disabled', false);
                        return alert("Seriale richiesto.");
                    }

                    /* Controllo vin già presente */
                    if (checkSerialExist(vinVal)) {
                        $('#btn-add-serials').attr('disabled', false);
                        return alert("Nr. Telaio VIN/FIN già presente!");
                    }

                    // if (pesoVal.length == 0) {
                    //     $('#btn-add-serials').attr('disabled', false);
                    //     return alert("Peso richiesto");
                    // }

                    if (serialiVal.length == 0) {
                        $('#btn-add-serials').attr('disabled', false);
                        return alert("Seriali da ritirare richiesti");
                    }

                    /* jsonizzo i seriali e poi li elaboro */
                    var serialiDaRitirareJson = JSON.parse(serialiVal);
                    var serialiToRetire = [];
                    serialiDaRitirareJson.map(function (serialeDaRitirare) {

                        serialiToRetire.push(serialeDaRitirare.value);
                    })

                    /* verifico l'esistenza del vin nella campagna */
                    let seriale = undefined;
                    if (campagna) {
                        seriale = await fetchData(APPURL + "/api/seriale/" + campagna.id + "/" + vinVal,
                            optionsHeader).catch((error) => { })
                        console.log(seriale);
                        if (!seriale || !seriale._id) {
                            $('#btn-add-serials').attr('disabled', false);
                            return alert(
                                "Spiacenti. Il seriale inserito non è stato trovato. Assicurati di aver inserito i caratteri corretti."
                            );
                        } else {
                            /* Check numero massimo seriali per campagna */
                            if (seriale.nr_elementi_ritirati_per_vin) {
                                var contSerialiRitirati = seriale.nr_elementi_ritirati_per_vin;
                            } else {
                                var contSerialiRitirati = 0;
                            }
                            if (campagna.numero_colli_massimo > 0 && campagna.numero_colli_massimo < serialiToRetire
                                .length + contSerialiRitirati) {
                                $('#btn-add-serials').attr('disabled', false);
                                return alert(
                                    "Il numero massimo di seriali ritirabili per questa campagna è " +
                                    campagna.numero_colli_massimo);
                            }
                            /* Check stato seriale */
                            if (seriale.status != "ACTIVE") {
                                $('#btn-add-serials').attr('disabled', false);
                                return alert("Seriale non attivo");
                            }
                        }
                    }
                    cont++;
                    // modifica richiesta 18/11 non più peso inserito a mano ma calcolato come mercedes su base peso pezzo medio
                    // var weight = parseFloat(pesoVal).toFixed(3);
                    var weight = parseFloat(campagna.peso).toFixed(3) * serialiToRetire.length;

                    /* creo l'oggetto con tutte le info del vin, che poi sarà quello inviato al controllores */
                    var vinObject = createSerialObject(seriale, cont, campagna, serialiToRetire, campaignKey, weight)

                    /* Carico il vin nell'array per verificare che non ci siano doppi */
                    vins[cont] = vinObject;

                    /* scrivo nell'input nascosto */
                    $("#serials").val(JSON.stringify(vins));

                    /* Imposto il peso e i colli totali */
                    valorizzazionePesoColli(vins);

                    /* Aggiungo il seriale alla tabella */
                    addToTable(vinObject);

                    /* Svuoto gli input */
                    $("#serial").val("");
                    $("#seriali").val("");
                    $("#peso_seriale").val("");
                    tagify.removeAllTags();

                    $('#saveAndConfirmBtn').show();
                    break;

                // vin + seriale
                case 2:

                    var vinVal = $('#serial').val();
                    // verifico che i campi siano compilati
                    if (vinVal.length == 0) {
                        $('#btn-add-serials').attr('disabled', false);
                        return alert("Seriale richiesto.");
                    }

                    /* Controllo vin già presente */
                    if (checkSerialExist(vinVal)) {
                        $('#btn-add-serials').attr('disabled', false);
                        return alert("Nr. Telaio VIN/FIN già presente!");
                    }

                    /* verifico l'esistenza del vin nella campagna */
                    let serialeB = undefined;
                    if (campagna) {
                        serialeB = await fetchData(APPURL + "/api/seriale/" + campagna.id + "/" + vinVal,
                            optionsHeader).catch((error) => { })
                            console.log(serialeB);
                        if (!serialeB || !serialeB._id) {
                            $('#btn-add-serials').attr('disabled', false);
                            return alert(
                                "Spiacenti. Il seriale inserito non è stato trovato. Assicurati di aver inserito i caratteri corretti."
                            );
                        } else {
                            /* Check numero massimo seriali per campagna */
                            if (serialeB.nr_elementi_ritirati_per_vin) {
                                var contSerialiRitirati = serialeB.nr_elementi_ritirati_per_vin;
                            } else {
                                var contSerialiRitirati = 0;
                            }
                            if (campagna.numero_colli_massimo > 0 && campagna.numero_colli_massimo < serialiToRetire
                                .length + contSerialiRitirati) {
                                $('#btn-add-serials').attr('disabled', false);
                                return alert(
                                    "Il numero massimo di seriali ritirabili per questa campagna è " +
                                    campagna.numero_colli_massimo);
                            }
                            /* Check stato seriale */
                            if (serialeB.status != "ACTIVE") {
                                $('#btn-add-serials').attr('disabled', false);
                                return alert("Seriale non attivo");
                            }
                            /* Check seriale già ritirato */
                            var isRetired = await fetchData(APPURL + "/campagna/" + campagna.id + "/vin/" + vinVal + "/check", optionsHeader).catch((error) => {});
                            if (isRetired.res) {
                                $('#btn-add-serials').attr('disabled', false);
                                return alert("Il seriale associato al telaio è già stato ritirato");
                            }
                     
                        }
                    }
                    /* per mercedes tiro dentro i seriali dalla risposta */
                    serialiToRetire = [];
                    for (let i = 0; i < serialeB.serials.length; i++) {
                        serialiToRetire.push(serialeB.serials[i]);
                    }

                    cont++;
                    var weight = parseFloat(campagna.peso).toFixed(3) * serialiToRetire.length;

                    /* creo l'oggetto con tutte le info del vin, che poi sarà quello inviato al controllores */
                    var vinObject = createSerialObject(serialeB, cont, campagna, serialiToRetire, campaignKey, weight)

                    /* Carico il vin nell'array per verificare che non ci siano doppi */
                    vins[cont] = vinObject;

                    /* scrivo nell'input nascosto */
                    $("#serials").val(JSON.stringify(vins));

                    /* Imposto il peso e i colli totali */
                    valorizzazionePesoColli(vins);

                    /* Aggiungo il seriale alla tabella */
                    addToTable(vinObject);

                    /* Svuoto gli input */
                    $("#serial").val("");
                    $("#seriali").val("");
                    $("#peso_seriale").val("");
                    tagify.removeAllTags();
                    $('#saveAndConfirmBtn').show();
                    break;

                default:
                    break;
            }
        } else {
            // rdr in campagna senza chiave campagna
            console.log('corretto');
            var vinVal = $('#serial').val();
            var pesoVal = $('#peso_seriale').val();
            var serialiVal = $('#seriali').val();

            // verifico che i campi siano compilati
            if (vinVal.length == 0) {
                $('#btn-add-serials').attr('disabled', false);
                return alert("Seriale richiesto.");
            }

            /* Controllo vin già presente */
            if (checkSerialExist(vinVal)) {
                $('#btn-add-serials').attr('disabled', false);
                return alert("Nr. Telaio VIN/FIN già presente!");
            }

            if (pesoVal.length == 0) {
                $('#btn-add-serials').attr('disabled', false);
                return alert("Peso richiesto");
            }

            if (serialiVal.length == 0) {
                $('#btn-add-serials').attr('disabled', false);
                return alert("Seriali da ritirare richiesti");
            }

            /* jsonizzo i seriali e poi li elaboro */
            var serialiDaRitirareJson = JSON.parse(serialiVal);
            var serialiToRetire = [];
            serialiDaRitirareJson.map(function (serialeDaRitirare) {

                serialiToRetire.push(serialeDaRitirare.value);
            })

            /* verifico l'esistenza del vin nella campagna */
            let seriale = undefined;
            if (campagna) {
                seriale = await fetchData(APPURL + "/api/seriale/" + campagna.id + "/" + vinVal,
                    optionsHeader).catch((error) => { })
                if (!seriale || !seriale._id) {
                    $('#btn-add-serials').attr('disabled', false);
                    return alert(
                        "Spiacenti. Il seriale inserito non è stato trovato. Assicurati di aver inserito i caratteri corretti."
                    );
                } else {
                    /* Check numero massimo seriali per campagna */
                    if (seriale.nr_elementi_ritirati_per_vin) {
                        var contSerialiRitirati = seriale.nr_elementi_ritirati_per_vin;
                    } else {
                        var contSerialiRitirati = 0;
                    }
                    if (campagna.numero_colli_massimo > 0 && campagna.numero_colli_massimo < serialiToRetire
                        .length + contSerialiRitirati) {
                        $('#btn-add-serials').attr('disabled', false);
                        return alert(
                            "Il numero massimo di seriali ritirabili per questa campagna è" +
                            campagna.numero_colli_massimo);
                    }
                    /* Check stato seriale */
                    if (seriale.status != "ACTIVE") {
                        $('#btn-add-serials').attr('disabled', false);
                        return alert("Seriale non attivo");
                    }
                }
            }
            cont++;
            var weight = parseFloat(pesoVal).toFixed(3);
            /* creo l'oggetto con tutte le info del vin, che poi sarà quello inviato al controllores */
            var vinObject = createSerialObject(seriale, cont, campagna, serialiToRetire, campaignKey, weight, vinVal)

            // console.log(vinObject);
            /* Carico il vin nell'array per verificare che non ci siano doppi */
            vins[cont] = vinObject;

            /* scrivo nell'input nascosto */
            $("#serials").val(JSON.stringify(vins));

            /* Imposto il peso e i colli totali */
            valorizzazionePesoColli(vins);

            /* Aggiungo il seriale alla tabella */
            addToTable(vinObject);

            /* Svuoto gli input */
            $("#serial").val("");
            $("#seriali").val("");
            $("#peso_seriale").val("");
            tagify.removeAllTags();

            $('#saveAndConfirmBtn').show();
        }

        $('#btn-add-serials').attr('disabled', false);

    });

    /**
    * Rimuovi seriale
    */
    $(".table-serials-body").on("click", ".btn-remove-serial", function () {
        var contToRemove = $(this).data("serial-cont");
        /* Rimuovo dagli array il seriale */
        vins[contToRemove] = null;
        $("#serials").val(JSON.stringify(vins));
        /* Rimuovo la riga dalla tabella */
        $("#row-serial-" + contToRemove).remove();
        /* Aggiorno il peso e i colli totali */
        let peso = 0;
        let colli = 0;
        let countSerial = 0;
        vins.map(function (vin) {
            if (vin) {
                countSerial++;
                peso += parseFloat(vin.weight);
                colli += vin.colli;
            }
        });
        if (peso < 1 && colli > 0) {
            peso = 1;
        }
        $("#colliVisible").val(colli);
        $("#pesoVisible").val(peso);

        $("#colli").val(colli);
        $("#peso").val(peso);

        if (peso == 0 && colli == 0) {
            $('#saveAndConfirmBtn').hide();
        }
    })

    // CALCOLO TARA CONTENITORI 
    var contenitore = 0;
    var numContenitori = 0;

    $('#scatola_cartone').on('click', function () {
        contenitore = $('#scatola_cartone').data('peso');
        numContenitori = $('#num_contenitori').val();
        var tara = contenitore * numContenitori;
        $('#tara-calc').empty();
        $('#tara-calc').append('Tara: ' + tara.toFixed(2) + ' Kg');

    });

    $('#box_plastica').on('click', function () {
        contenitore = $('#box_plastica').data('peso');
        numContenitori = $('#num_contenitori').val();
        var tara = contenitore * numContenitori;
        $('#tara-calc').empty();
        $('#tara-calc').append('Tara: ' + tara.toFixed(2) + ' Kg');

    });

    $('#fusto_plastica').on('click', function () {
        contenitore = $('#fusto_plastica').data('peso');
        numContenitori = $('#num_contenitori').val();
        var tara = contenitore * numContenitori;
        $('#tara-calc').empty();
        $('#tara-calc').append('Tara: ' + tara.toFixed(2) + ' Kg');

    });

    $('#num_contenitori').on('change', function () {
        numContenitori = $('#num_contenitori').val();

        if ($('#fusto_plastica').is(':checked')) {
            contenitore = $('#fusto_plastica').data('peso');
        } else if ($('#box_plastica').is(':checked')) {
            contenitore = $('#box_plastica').data('peso');
        } else if ($('#scatola_cartone').is(':checked')) {
            contenitore = $('#scatola_cartone').data('peso');
        }

        var tara = contenitore * numContenitori;
        $('#tara-calc').empty();
        $('#tara-calc').append('Tara: ' + tara.toFixed(2) + ' Kg');
    });

    function checkSerialExist(vinToCheck) {
        let exist = false;
        vins.map(function (vin) {
            if (vin && vin.vin == vinToCheck) {
                exist = true;
            }
        });
        return exist;
    }

    function createSerialObject(seriale, cont, campagna, serialiToRetire, campaignKey, weight, vinVal) {
        let campaignSerial = {
            id: seriale ? seriale._id : null,
            cont: cont,
            vin: seriale ? seriale.VIN : vinVal,
            casa_automobilistica_id: campagna ? campagna.anagrafica_id : null,
            weight: weight,
            campagna_id: campagna ? campagna.id : null,
            campagna_key: campaignKey,
            num_ritirati: null,
            stato: seriale ? seriale.status : null,
            campagna: campagna,
            seriali: serialiToRetire,
            colli: serialiToRetire.length,
        }

        return campaignSerial;
    }

    function valorizzazionePesoColli(vins) {
        let peso = 0;
        let colli = 0;
        vins.map(function (serial) {
            if (serial) {
                peso += parseFloat(serial.weight);
                colli += serial.colli;
            }
        });
        if (peso < 1) {
            peso = 1;
        }

        $("#colliVisible").val(colli);
        $("#pesoVisible").val(peso);

        $("#colli").val(colli);
        $("#peso").val(peso);
    }

    function addToTable(campaignSerial) {
        $(".table-serials-body").append(`
                        <tr id="row-serial-${campaignSerial.cont}" >
                            <th scope="row">${cont}</th>
                            <td>${campaignSerial.vin}</td>
                            <td>${campaignSerial.campagna ? campaignSerial.campagna.nome : "Campagna o VIN non corretti"} (${campaignSerial.campagna_key})</td>
                            <td>${campaignSerial.seriali.join(', ')}</td>
                            <td>${campaignSerial.colli}</td>
                            <td>${campaignSerial.weight}</td>
                            <td><button type="button" class="btn-remove-serial btn btn-sm btn-danger" data-serial-id="${campaignSerial.id}" data-serial-cont="${campaignSerial.cont}" ><i class="far fa-trash-alt"></i> Rimuovi </button></td>
                        </tr>
                    `);

    }

    $('#scatola_cartone').on('click', function () {
        var img = $('#scatola_cartone').data('img');

        $('#img_contenitore').attr("src", img).show();
    });

    $('#box_plastica').on('click', function () {
        var img = $('#box_plastica').data('img');

        $('#img_contenitore').attr("src", img).show();
    });

    $('#fusto_plastica').on('click', function () {
        var img = $('#fusto_plastica').data('img');

        $('#img_contenitore').attr("src", img).show();
    });

    // prevent multiple submit
    $('#serial-form').on('submit', function () {
        $('#save-confirm-btn').hide();
        $('#loading-btn').show()

        setTimeout(() => {
            $('#save-confirm-btn').show();
            $('#loading-btn').hide()
        }, 3000);
    });

});
