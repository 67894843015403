$('body.preventivi.add-rows, body.preventivi.admin.show').each(function() {

    // custom file style
    $(document).on('change', '.custom-file-input', function (event) {
        $(this).next('.custom-file-label').html(event.target.files[0].name);
    });

    $('.btnSendRowAttachment').on('click', async function() {
        const $this = $(this);
        const url = $this.data('url');
        const uploadSection = $this.closest('.uploadAttachment');
        console.log(uploadSection[0]);
        const formdata = new FormData();
        const inputField = uploadSection.find('input');
        if (!inputField.val()) {
            return;
        }
        formdata.append('attachment', inputField[0].files[0]);
        uploadSection.find('.progress').removeClass('d-none');
        uploadSection.find('.input-group').addClass('d-none');
        try {
            // console.log('formdata', formdata);
            const response = await axios.post(url, formdata);
            window.location.reload();
            // console.log('waiting...');
            await new Promise(r => setTimeout(r, 5000));
            console.log('done.');
        } catch (error) {
            console.log(error);
            alert("Si è verificato un errore.", error);
        }
        uploadSection.find('.progress').addClass('d-none');
        uploadSection.find('.input-group').removeClass('d-none');
    });

    $('.btnDeleteRowAttachment').on('click', async function() {
        const url = $(this).data('url');
        if (!confirm('Sei sicuro di voler eliminare questo allegato?')) {
            return;
        }
        try {
            const response = await axios.delete(url);
            window.location.reload();
        } catch (error) {
            console.log(error);
            alert("Si è verificato un errore.", error);
        }
    });

});
