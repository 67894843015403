
$('body.preventivi.admin.show').each(function(){
    const form = $('#preventivoForm');

    // Base table management
    class BaseCostiRicaviTable {
        opts = {};
        tableEl = null;
        tableBody = null;
        tableFooter = null;
        rowTemplate = null;
        totalValue = 0;
        updateTotalCbk = null;

        constructor(el, opts) {
            this.tableEl = el;
            this.opts = opts;
            this.tableBody = this.tableEl.find('tbody');
            this.tableFooter = this.tableEl.find('tfoot');

            this._getRowTemplate();
			
			
            this.addRow();

        }

        _getRowTemplate() {
            this.rowTemplate = this.tableEl.find('.row-template').detach();
        }

        // AttachRowEvents
        _attachRowEvents(row) {
            row.find('.btnAddRow').click(() => {
                this.addRow();
            });
            row.find('.btnDelRow').click(() => {
                this.removeRow(row);
            });

            row.find('input').on('keyup change', () => {
                this.recalculateTotals();
            });
        }

        // Add Row
        addRow(rowData=null) {
            console.log('Add row');
            const row = this.rowTemplate.clone();
            if (rowData) {
                this.fillRow(row, rowData);

				if (!!rowData.quotazione_fornitore_id) {
					this.disableRow(row);
				}
            }
			
            this._attachRowEvents(row);
            this.tableBody.append(row);
        }

        // Remove Row
        removeRow(row) {
            if (this.tableBody.find('tr').length > 1) {
                console.log('Remove row');
                row.detach();
                this.recalculateTotals();
            }
        }

        // Recalculate Totals
        recalculateTotals() {
            console.log('Calculating totals...');
            this.totalValue = 0;

			//this.totalValue += (this.get_quotazione_total() || 0);
			
            this.tableBody.find('tr').each((_idx, el) => {
                this.totalValue += this.calculateSingleRowTotal($(el));
            });

            this.updateTableTotal(this.totalValue);
			
            if (this.updateTotalCbk) {
                this.updateTotalCbk(this.totalValue);
            }
        }

        // redefine for specific table
        calculateSingleRowTotal(row) {
            console.error('Not implemented');
        }

		get_quotazione_total() {
            console.error('Not implemented -quotation');
		}
        // Update table total
        updateTableTotal(total) {
            console.error('Not implemented');
        }

        onUpdateTotal(cbk) {
            this.updateTotalCbk = cbk;
        }

        fillRow(row, data) {
            return row;
        }

        loadRows(rows) {
			let quot_rows = this.tableBody.find("tr.quotazioneRow, tr.quotazioneRowSkip");
            
			this.tableBody.empty();
			
			if (quot_rows.length > 0) {
				this.tableBody.append(quot_rows);
			}
			
            rows.forEach(row => {
                this.addRow(row);
            });
			
            this.recalculateTotals();
        }

		disableRow(row) {
			row.find('.btnDelRow').css("display", "none");
            row.find('input[name="dare_logistico[]"]').prop("disabled", true);
            row.find('input[name="dare_descrizione[]"]').prop("disabled", true);
            row.find('select[name="dare_unita_misura[]"]').prop("disabled", true);
            row.find('input[name="dare_valore_unitario[]"]').prop("disabled", true);
            row.find('input[name="dare_qta[]"]').prop("disabled", true);
            row.find('input[name="dare_valore[]"]').prop("disabled", true);

            return row;
		}
		
		activateRow(row) {
			row.find('.btnDelRow').css("display", "inline");
            row.find('input[name="dare_logistico[]"]').prop("disabled", false);
            row.find('input[name="dare_descrizione[]"]').prop("disabled", false);
            row.find('select[name="dare_unita_misura[]"]').prop("disabled", false);
            row.find('input[name="dare_valore_unitario[]"]').prop("disabled", false);
            row.find('input[name="dare_qta[]"]').prop("disabled", false);
            row.find('input[name="dare_valore[]"]').prop("disabled", false);

            return row;
			
		}

    }

    // TABELLA COSTI
    class CostiTable extends BaseCostiRicaviTable {
        // redefine for specific table
        calculateSingleRowTotal(row) {
            // console.group('calculateSingleRowTotal');
            // console.log('row', row);
            // console.log(row.find('input[name="dare_valore_unitario[]"]'));
            const valoreUnitario = parseFloat(row.find('input[name="dare_valore_unitario[]"]').val());
            const quantita = parseFloat(row.find('input[name="dare_qta[]"]').val());
            let total = 0;
            console.log('valore unitario', valoreUnitario, 'quantità', quantita);
            if (valoreUnitario && quantita) {
                total = valoreUnitario * quantita;
            }
            // console.groupEnd();
            row.find('input[name="dare_valore[]"]').val(total.toFixed(2));
            return total;
        }
		
		get_quotazione_total() {
			return 0;
		}

        updateTableTotal(total) {
            this.tableFooter.find('.dare-totale').text(total.toFixed(2));
        }

        fillRow(row, data) {
            row.find('input[name="dare_logistico[]"]').val(data.logistico);
            row.find('input[name="dare_descrizione[]"]').val(data.descrizione);
            row.find('select[name="dare_unita_misura[]"]').val(data.unita_misura);
            row.find('input[name="dare_valore_unitario[]"]').val(data.valore_unitario);
            row.find('input[name="dare_qta[]"]').val(data.qta);
            row.find('input[name="dare_valore[]"]').val(data.valore);
            return row;
        }
		
    }

    // TABELLA RICAVI
    class RicaviTable extends BaseCostiRicaviTable {
        // redefine for specific table
        calculateSingleRowTotal(row) {
            // console.group('calculateSingleRowTotal');
            // console.log('row', row);
            // console.log(row.find('input[name="dare_valore_unitario[]"]'));
            const valoreUnitario = parseFloat(row.find('input[name="avere_valore_unitario[]"]').val());
            const quantita = parseFloat(row.find('input[name="avere_qta[]"]').val());
            let total = 0;
            console.log('valore unitario', valoreUnitario, 'quantità', quantita);
            if (valoreUnitario && quantita) {
                total = valoreUnitario * quantita;
            }
            // console.groupEnd();
            row.find('input[name="avere_valore[]"]').val(total.toFixed(2));
            return total;
        }

		get_quotazione_total() {
			return 0;
		}

        updateTableTotal(total) {
            this.tableFooter.find('.avere-totale').text(total.toFixed(2));
        }

        fillRow(row, data) {
            row.find('input[name="avere_logistico[]"]').val(data.logistico);
            row.find('input[name="avere_descrizione[]"]').val(data.descrizione);
            row.find('select[name="avere_unita_misura[]"]').val(data.unita_misura);
            row.find('input[name="avere_valore_unitario[]"]').val(data.valore_unitario);
            row.find('input[name="avere_qta[]"]').val(data.qta);
            row.find('input[name="avere_valore[]"]').val(data.valore);
            return row;
        }
		
    }

    $('.preventivo-row').each((_idx, el) => {
        const row = $(el);
        const tableCosti = new CostiTable(row.find('.dare-table'), {});
        const tableRicavi = new RicaviTable(row.find('.avere-table'), {});

        // Margine
        tableCosti.onUpdateTotal((total) => {
            row.find('.costo-minimo').text(total.toFixed(2));
            row.find('input[name=margine_perc]').trigger('change');
        });

        row.find('input[name=margine_perc]').on('keyup change', () => {
            const marginePerc = parseFloat(row.find('input[name=margine_perc]').val());
            const margineValElement = row.find('input[name=margine_valore]');
            const minimoFatturabileElement = row.find('input[name=minimo_fatturabile_cliente]');
            const costoMinimo = parseFloat(row.find('.costo-minimo').text());

            const margineVal = marginePerc * costoMinimo / 100;
            const minimoFatturabile = costoMinimo + margineVal;
            margineValElement.val(margineVal.toFixed(2));
            minimoFatturabileElement.val(minimoFatturabile.toFixed(2));
        });

        // Save Row estimate
        row.find('.btnSave').on('click', async () => {
            console.log('submit!');
            const data = row.serialize();
            try {
                const result = await axios.post(row.attr('action'), data);
                if (result.data.success) {
                    window.alert('Riga del preventivo salvata.');
                    window.location.reload();
                    refreshData(result.data.estimate);
                } else {
                    throw { response: resultSend };
                }
            } catch (error) {
                console.log(error.response);
                window.alert('Si è verificato un errore: ' + error.response?.data?.message ?? 'Errore sconosciuto');
            }
        });

        // Load current estimate data
        async function refreshData(estimate) {
            if (estimate.dare_rows.length > 0) {
                tableCosti.loadRows(estimate.dare_rows);
            }
            if (estimate.avere_rows.length > 0) {
                tableRicavi.loadRows(estimate.avere_rows);
            }
            row.find('input[name=margine_perc]').val(estimate.margine_perc).trigger('change');

            row.find('textarea[name=preventivo_note_interne]').val(estimate.note_interne);
            row.find('textarea[name=preventivo_note_cliente]').val(estimate.note_cliente);
            row.find('input[name=preventivo_ente]').val(estimate.ente);
            row.find('input[name=preventivo_addebito_cliente]').val(estimate.addebito_cliente);
            row.find('input[name=preventivo_riconosciuto_cliente]').val(estimate.riconosciuto_cliente);
        }

        async function loadData() {
            const result = await axios.get(row.data('get-url'));
            if (result.data?.estimate) {
                const estimate = result.data.estimate;
                refreshData(estimate);
            }
        }

        loadData();


        // Add RDR functions
        row.find('.btnAddRDR').on('click', function () {
            const btn = $(this);
            const url = btn.data('url');

            // set action attributes to modal form and open modal
            const modal = $('#addRDRModal');
            modal.find('form').attr('action', url);
            modal.find('.idRiga').text('AAAA');
            modal.modal('show');

        })
    });

    $('#consuntivoForm').each(function () {
        const form = $(this);
        form.find('input[name=consuntivo_costi_aggiuntivi]').on('keyup', function () {
            const totale = parseFloat(form.find('input[name=totale').val() || 0);
            const costiAggiuntivi = parseFloat($(this).val() || 0);
            const netto = totale + costiAggiuntivi;
            $('input[name=consuntivo_netto]').val(netto.toFixed(2));
        });

        form.find('input[name=consuntivo_costi_aggiuntivi]').trigger('keyup');

        form.find('.btnGenerateFatturazione').on('click', async function() {
            const btn = $(this);
            const url = btn.attr('href');
            // send data to server
            try {
                const response = await axios.post(url);
                if (response.data.success) {
                    window.alert('Dati fatturazione generati correttamente.');
                    window.location.reload();
                } else {
                    window.alert('Si è verificato un errore: ' + response.data.message);
                }
            } catch (error) {
                console.log(error);
                alert("Si è verificato un errore.", error);
            }
        });
    });
    // per le singole righe 
    $('.consuntivoRowForm').each(function () {
        const form = $(this);
        var row = form.data('row');
        form.find('input[name=consuntivo_costi_aggiuntivi_'+row+']').on('keyup', function () {
            const totale = parseFloat(form.find('input[name=totale_'+row+'').val() || 0);
            const costiAggiuntivi = parseFloat($(this).val() || 0);
            const netto = totale + costiAggiuntivi;
            $('input[name=consuntivo_netto_'+row+']').val(netto.toFixed(2));
        });

        form.find('input[name=consuntivo_costi_aggiuntivi_'+row+']').trigger('keyup');

        form.find('.btnGenerateFatturazione_'+row+'').on('click', async function() {
            const btn = $(this);
            const url = btn.attr('href');
            // send data to server
            try {
                const response = await axios.post(url);
                if (response.data.success) {
                    window.alert('Dati fatturazione generati correttamente.');
                    window.location.reload();
                } else {
                    window.alert('Si è verificato un errore: ' + response.data.message);
                }
            } catch (error) {
                console.log(error);
                alert("Si è verificato un errore.", error);
            }
        });
    });


    $('.btnGenerateFatturazioneStorno').on('click', async function() {
        const btn = $(this);
        if (!window.confirm('Sei sicuro di voler generare i record di storno?')) {
            return;
        }
        const url = btn.attr('href');
        // send data to server
        try {
            const response = await axios.post(url);
            if (response.data.success) {
                window.alert('Dati fatturazione storno generati correttamente.');
                window.location.reload();
            } else {
                window.alert('Si è verificato un errore: ' + response.data.message);
            }
        } catch (error) {
            console.log(error);
            alert("Si è verificato un errore.", error);
        }
    });

    // Submit
    $('.btnSend').on('click', async () => {
        console.log('submit!');
        const url = $('.btnSend').data('send-url');
        const data = {
            'selected_um': $('input[name=selected_um]:checked').val(),
        }
        const resultSend = await axios.post(url, data);
        if (resultSend.data.success) {
            window.alert('Preventivo inviato.');
            window.location.assign(resultSend.data.redirect);
        } else {
            throw { response: resultSend };
        }
    });

    // Add RDR Modal
    const addRDRModal = $('#addRDRModal');
    addRDRModal.find('.btnGenerateRDR').on('click', async function () {
        const form = addRDRModal.find('form');

        const serializedForm = form.serialize();
        console.log(serializedForm);

        // send data to server
        try {
            const response = await axios.post(form.attr('action'), serializedForm);
            window.location.reload();
        } catch (error) {
            console.log(error);
            alert("Si è verificato un errore.", error);
        }

    });


});


$(function () {
    
    $(".btnSendFornitore").each(function(idx, elem) {
        elem = $(elem);
        elem.on("click", function (ev) {
            let select_fornitore = $($(".selectFornitore")[idx]);
            let fornitore_id = select_fornitore.val();
            let note_elem = $($(".noteFornitore")[idx]);
            let btn = $(this);

            if (!fornitore_id || fornitore_id == "0") {
                alert("Indicare il fornitore!");
                select_fornitore.trigger("focus");
                return false;
            }

            $.ajax(btn.data("url"), {
                method:"post",
                data: {
                    "note" : note_elem.val(),
                    "fornitore_id" : fornitore_id,
                    "_token": elem.parents("form")[0]._token.value    
                
                }
            }).done(function (resp) {
                alert("Preventivo inviato con successo");
                window.location.reload();
            
            }).fail(function (resp) {
                console.log(resp);
            });


        });
        
    });

});
