
$('body.anagrafiche.batterycatalog.show.storage').each(() => {

    $('.btnValidate').on('click', async function () {
        const btn = $(this);
        const url = btn.data('url');
        if (window.confirm("ATTENZIONE. Validare la batteria?")) {
            const result = await axios.post(url);
            if (result.data.result === 'OK') {
                window.alert(`Sbloccate ${result.data.rdrs} RDR`);
                window.location.reload();
            } else {
                window.alert('ATTENZIONE - Errore durante la validazione.');
            }
        }
    });

    $('.btnDelete').on('click', async function () {
        const btn = $(this);
        const url = btn.data('url');
        if (window.confirm("ATTENZIONE. Eliminare la batteria dal catalogo?\nL'operazione è irreversibile.")) {
            try {
                const result = await axios.delete(url);
                if (result.data.result === 'OK') {
                    window.location.assign(result.data.redirect_url);
                }
            } catch (error) {
                console.error(error);
                window.alert("Si è verificato un errore: " + error);
            }
        }
    });

});
