const { event } = require("jquery");

$('body.rdr.ftv-create').each(() => {


    $('#search_serial-searchBtn').on('click', async function () {
        const url = '/api/ftv/serial';
        const serial = $('#search_serial').val();
        if (serial) {
            console.log(serial);
            const response = await axios.get(url, {
                params: {
                    serial
                }
            });
            if (response) {
                if (response.data) {
                    //  aggiungo linea alla table con i dati del pannello - check su sede  = alle altre
                    console.log(response.data);

                    if (response.data.results == 'bad') {
                        $('#alert-box').show();
                        $('#alertBox').empty();
                        $('#alertBox').append(
                            'il seriale inserito non è in garanzia. Richiedi un preventivo a Cobat Spa per il ritiro.'
                        )
                    } else {
                        if ($('#' + response.data.seriale).length == 0) {
                            if (response.data.sedeAttivazione) {
                                $('#ftv-select-table').append(
                                    '<tr data-sede="' + response.data.sedeAttivazione.id + '"><td> <input type="checkbox" class="moduli" name="moduli[]" data-peso="' + response.data.peso + ' " data-sede="' + response.data.sedeAttivazione.id + '" value="' + response.data.seriale + '" id="' + response.data.seriale + '">  </td> <td> ' + response.data.seriale + ' </td> <td> ' + response.data.ragioneSociale + ' </td> <td> ' + response.data.listino[0].categoria.name + ' </td> <td> ' + response.data.listino[0].sottocategoria.name + ' </td> <td> ' + response.data.listino[0].tipologia.name + ' </td> <td> ' + response.data.sedeAttivazione.indirizzo + ' ' + response.data.sedeAttivazione.numero_civico + '. ' + response.data.sedeAttivazione.comune + '</td> <td> ' + response.data.peso + ' </td> </tr>'
                                );

                                // $('<tr>').data('sede', response.data.sedeAttivazione.id)
                                // .html([
                                //     $('<td>').html([
                                //         $('<input>').data('sede', response.data.sedeAttivazione.id).val(response.data.seriale),
                                //     ]),
                                //     $('<td>')
                                // ]);

                            } else {
                                $('#alert-box').show();
                                $('#alertBox').empty();
                                $('#alertBox').append(
                                    `Il seriale ${response.data.seriale} risulta installato ma non è possibile rinvenire la sede d'installazione. Se il problema persiste contattare l'amministratore di sistema.`
                                )
                            }


                            var isPresent = false;
                            $("#sedi_terze_option option").each(function () {
                                var thisOptionValue = $(this).val();

                                if (thisOptionValue == response.data.sedeAttivazione.id) {
                                    isPresent = true;
                                }
                            });

                            if (!isPresent) {
                                $('#sedi_terze_option').append(
                                    '<option value="' + response.data.sedeAttivazione.id + '"> ' + response.data.sedeAttivazione.indirizzo + ' ' + response.data.sedeAttivazione.numero_civico + ' ' + response.data.sedeAttivazione.comune + ' ' + response.data.sedeAttivazione.cap + ' (' + response.data.sedeAttivazione.provincia + ') (sede installazione) </option>'
                                );
                            }

                            // ' + response.data.sedeAttivazione.indirizzo + ' ' + response.data.sedeAttivazione.numero_civico + ' ' + response.data.sedeAttivazione.comune  + ' ' + response.data.sedeAttivazione.cap  +' (' + response.data.sedeAttivazione.provincia + ')
                        } else {
                            $('#alert-box').show();
                            $('#alertBox').empty();
                            $('#alertBox').append(
                                'il seriale: ' + response.data.seriale + ' inserito risulta già presente.'
                            )
                        }
                    }
                }
            }
        }
    });

    $('#close-alert').on('click', function () {
        $('#alert-box').hide();

    });


    $('#conf-rdr').on('click', function(e) {
        e.preventDefault();
        var moduli = document.getElementsByName('moduli[]');
        var canOpenRdr = true;
        var hasSede = $('#sede_id').val();
        var errorMessage = '';

        if ($('#colli').val() < 1 || !isNaN($('#colli').val())) {
            errorMessage = 'Numero colli è richiesto. <br>';
        }
        if ($('#ingombro_altezza').val() < 1 || $('#ingombro_larghezza').val() < 1 || $('#ingombro_profondita').val() < 1) {
            errorMessage += "Le dimensioni dell'ingombro sono necessarie.";
            $('#ingombro_altezza').addClass('is-invalid');
            $('#ingombro_larghezza').addClass('is-invalid');
            $('#ingombro_profondita').addClass('is-invalid');

        }
        if ($('#moduli_cornice_alluminio').val() == '' ) {
            errorMessage += "Mancano i dati sul tipo di cornice.";
            $('#moduli_cornice_alluminio').addClass('is-invalid');
        }
        if ($('#moduli_imbancalati').val() == '' ) {
            errorMessage += "Specificare se i moduli sono già pronti per il ritiro.";
            $('#moduli_imbancalati').addClass('is-invalid');
        }
        // if ($('#kg_max_bancale').val() == '' ) {
        //     errorMessage += "Specificare il peso di un bancale. ";
        //     $('#kg_max_bancale').addClass('is-invalid');
        // }
        // if ($('#n_moduli_vetro_danneggiato').val() ) {
        //     errorMessage += "Non possono esserci più moduli con vetro danneggiato che moduli selezionati.";
        //     $('#n_moduli_vetro_danneggiato').addClass('is-invalid');
        // }
        if ($('#disponibilita_muletto').val() == '' ) {
            errorMessage += "Specificare se è disponibile un muletto in loco.";
            $('#disponibilita_muletto').addClass('is-invalid');
        }
        if ($('#disponibilita_bilico').val() == '' ) {
            errorMessage += "Specificare se il sito è accessibile da un camion con bilico.";
            $('#disponibilita_bilico').addClass('is-invalid');
        }
        if ($('#disponibilita_motrice').val() == '' ) {
            errorMessage += "Specificare se il sito è accessibile da un camion con motrice.";
            $('#disponibilita_motrice').addClass('is-invalid');
        }
        console.log($('#flag_moduli_imbancalati').val());
        if (!$('#flag_moduli_imbancalati').is(':checked') ) {
            errorMessage += "Confermare che i moduli siano imbancalati con la superficie fotovoltaica rivolta verso l'alto.";
            $('#flag_moduli_imbancalati').addClass('is-invalid');
        }
        // if ($('#sito_movimentazione_transpallet').val() == '' ) {
        //     errorMessage += "Specificare se il sito permette la mobilitazione tramite transpallet.";
        //     $('#sito_movimentazione_transpallet').addClass('is-invalid');
        // }
        if (!canOpenRdr || hasSede == '') {
            errorMessage += 'Non è possibile aprire la rdr se non sono selezionati dei moduli in garanzia e la sede di ritiro. '; 
        }
        
        if ( errorMessage == '') {
            //check sui campi extra 
            if (confirm("Sei sicuro di voler aprire la rdr? Una volta confermato NON sarà più possibile modificare i dati inseriti.")) {
                $('#ftv-form').trigger('submit');
            }
        } else {
            return alert(errorMessage);
        }
    })


    $(document).on('click', '#rdr-modify', function () {
        console.log('click');
        enableInput();


        $('.moduli').each(function () {

            $(this).parents('tr').css('background-color', 'white');
            console.log($(this).parents('tr'));
        });

    })

    function disableInput() {

        $('.moduli').attr('onClick', 'return false');

        $('input').attr('readonly', true);

        $('.radios').attr('onClick', 'return false');

        $('select').attr('readonly', true);

        $('select option:not(:selected)').attr('disabled', true);
    }

    function enableInput() {

        $('.moduli').removeAttr('onClick');

        $('input').removeAttr('readonly');

        $('.radios').removeAttr('onClick');

        $('select').removeAttr('readonly');

        $('select option:not(:selected)').removeAttr('disabled');

        $('#verify-rdr').show();
        $('#conf-rdr').hide();
        $('#alert-box').hide();

    }


});
