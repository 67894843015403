$('body.users.index').each(() => {
    // Activate Autocomplete
    const anagraficaAutocomplete = $('#anagrafica_id');
    const selected_anagrafica_id_value = $('#anagrafica_id').data('selected-value');
    const selected_anagrafica_id_text = $('#anagrafica_id').data('selected-text');
    // console.log(selected_anagrafica_id_value, selected_anagrafica_id_text);
    anagraficaAutocomplete.autoComplete();
    if (selected_anagrafica_id_value) {
        anagraficaAutocomplete.autoComplete('set', { value: selected_anagrafica_id_value, text: selected_anagrafica_id_text});
    }

});