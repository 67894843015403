
$('body.preventivi.add-rows').each(function(){

    /*
     * Add row to preventivi
     */
    $('.btnSaveRow').on('click', async function(event) {
        // get current form
        const form = $(this).parents('form');
        // validate bootstrap form
        console.log(form.get(0));
        const isValid = form.get(0).checkValidity();
        form.addClass('was-validated');

        if (isValid) {
            const serializedForm = form.serialize();
            console.log(serializedForm);

            // send data to server
            try {
                const response = await axios.post(form.attr('action'), serializedForm);
                window.location.reload();
            } catch (error) {
                console.log(error);
                alert("Si è verificato un errore.", error);
            }
        }

    });

    /*
     * Delete row from preventivi
     */
    $('.btnDeleteRow').on('click', async function(event) {
        const url = $(this).data('url');
        if (!confirm("Sei sicuro di voler eliminare questa riga?")) {
            return;
        }

        try {
            const response = await axios.delete(url);
            window.location.reload();
        } catch (error) {
            console.log(error);
            alert("Si è verificato un errore.", error);
        }
    });

    $('.btnTerminaEInvia').on('click', async () => {
        $('#c1').removeClass('is-invalid')
        if (!$('#c1').prop('checked')) {
            alert("Devi accettare le condizioni di Ritiro prima di poter terminare il preventivo.");
            $('#c1').addClass('is-invalid')
            return;
        }
        if (!confirm("Sei sicuro di voler inviare il preventivo?")) {
            return;
        }
        const url = $('.btnTerminaEInvia').data('url');
        try {
            const response = await axios.post(url);
            if (response.data.success) {
                window.location.reload();
            } else {
                window.alert(response.data.message);
            }
        } catch (error) {
            console.log(error);
            alert("Si è verificato un errore.", error);
        }
    });


    function updateFTVTotals() {
        const ftvRow = $('.ftvRow');
        let totalPezzi = 0, totalPeso = 0;
        ftvRow.each(function(index, row) {
            const pezzi = parseInt($(row).find('.ftvQta').val());
            const peso = parseFloat($(row).find('.ftvPeso').val());

            if (pezzi && peso) {
                const pesoRowTotal = pezzi * peso;
                $(row).find('.ftvPesoTot').val(pesoRowTotal);
                totalPezzi += pezzi;
                totalPeso += pesoRowTotal;
            }
        });

        $('input[name=celle_numero_tot').val(totalPezzi);
        $('input[name=peso').val(totalPeso);
    }

    $('.ftvRow .ftvQta, .ftvRow .ftvPeso').on('keyup change', () => {
        updateFTVTotals();
    });

});
