import { Loader } from "@googlemaps/js-api-loader";
import MarkerClusterer from '@googlemaps/markerclustererplus';

$('body.ftv.ftv-regionMap').each(() => {

  const gmLoader = new Loader({
    apiKey: document.head.querySelector('meta[name="google-apikey"]').content,
    version: "weekly",
  });

  const mapStyle = [
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  let map;
  let markers;

  $('#regionMap').each(function () {
    gmLoader.load().then(async () => {
      // 42.1927027,13.4606529
      const centerPosition = { lat: 42.1927027, lng: 13.4606529 };
      const bounds = new google.maps.LatLngBounds();
      // Create map
      map = new google.maps.Map(document.getElementById("regionMap"), {
        center: centerPosition,
        zoom: 6,
        // mapTypeId: 'terrain'
        styles: mapStyle
      });

      const infowindow = new google.maps.InfoWindow();

      const dataURL = $('#regionMap').data('url');

      // Lista sedi
      const result = await axios.get(dataURL);
      const features = result.data.features;
      // per ogni sede creo un marker
      markers = features.map( (sede, i) => {
        const tot_moduli = sede.properties.numero_moduli;
        const marker = new google.maps.Marker({
            position: { lat: sede.geometry.coordinates[1], lng: sede.geometry.coordinates[0] },
            title: sede.properties.ragione_sociale,
        });
        marker.addListener('click', () => {
            const ragione_sociale = '<b>' + sede.properties.ragione_sociale + '</b>';
            const url = sede.properties.url;

            var content = `<p>${ragione_sociale}<br>Moduli: ${tot_moduli.toLocaleString()}</p>`;
            if (url) {
                content += `<p class="text-center"><a href="${url}" target="_blank" class="btn btn-sm btn-outline-primary">Vai al dettaglio</a></div>`;
            }

            infowindow.setContent(content);
            infowindow.open(map, marker);
        });
        bounds.extend(marker.position);
        return marker;
      });

      map.fitBounds(bounds);
      google.maps.event.addListenerOnce(map, 'idle', () => {
        new MarkerClusterer(map, markers, {
            gridSize: 50,
            imagePath:
              "/img/solecobat/markers/m",
          });
      });


    //   window['GMAP'] = map;
    });

  });

});
