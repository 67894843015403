$('#tipologia_campagna').on('change', function() {

    if ($('#tipologia_campagna').val() == 3) {
        
        $('#numero_seriali-block').hide();
        $('#numero_colli-block').hide();
        $('#numero_colli_massimo-block').hide();
        $('#dimesione-block').hide();
        $('#massa_esplosiva-block').hide();
        
    } else 
    {
        $('#numero_seriali-block').show();
        $('#numero_colli-block').show();
        $('#numero_colli_massimo-block').show();
        $('#dimesione-block').show();
        $('#massa_esplosiva-block').show();
    }
});