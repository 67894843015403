$('body.rdr.create.default').each(() => {

    $('#scatola_cartone').on('click', function() {
        console.log('in');
        var img =  $('#scatola_cartone').data('img'); 
         
        $('#img_contenitore').attr("src", img).show();
     });
 
     $('#box_plastica').on('click', function() {
         var img =  $('#box_plastica').data('img'); 
          
         $('#img_contenitore').attr("src", img).show();
      });
 
      $('#fusto_plastica').on('click', function() {
         var img =  $('#fusto_plastica').data('img'); 
          
         $('#img_contenitore').attr("src", img).show();
      });
 

    // CALCOLO TARA CONTENITORI 
    var contenitore = 0;
    var numContenitori = 0;

    $('#scatola_cartone').on('click', function () {
        contenitore = $('#scatola_cartone').data('peso');
        numContenitori = $('#num_contenitori').val();
        var tara = contenitore * numContenitori;
        $('#tara-calc').empty();
        $('#tara-calc').append('Tara: ' + tara.toFixed(2) + ' Kg');

    });

    $('#box_plastica').on('click', function () {
        contenitore = $('#box_plastica').data('peso');
        numContenitori = $('#num_contenitori').val();
        var tara = contenitore * numContenitori;
        $('#tara-calc').empty();
        $('#tara-calc').append('Tara: ' + tara.toFixed(2) + ' Kg');

    });

    $('#fusto_plastica').on('click', function () {
        contenitore = $('#fusto_plastica').data('peso');
        numContenitori = $('#num_contenitori').val();
        var tara = contenitore * numContenitori;
        $('#tara-calc').empty();
        $('#tara-calc').append('Tara: ' + tara.toFixed(2) + ' Kg');

    });

    $('#num_contenitori').on('change', function () {
        numContenitori = $('#num_contenitori').val();

        if ($('#fusto_plastica').is(':checked')) {
            contenitore = $('#fusto_plastica').data('peso');
        } else if ($('#box_plastica').is(':checked')) {
            contenitore = $('#box_plastica').data('peso');
        } else if ($('#scatola_cartone').is(':checked')) {
            contenitore = $('#scatola_cartone').data('peso');
        }

        var tara = contenitore * numContenitori;
        $('#tara-calc').empty();
        $('#tara-calc').append('Tara: ' + tara.toFixed(2) + ' Kg');
    });

});