$('body.preventivi.show').each(function () {

    $('.btnAccept').on('click', async function () {
        const url = $(this).data('url');
        if (confirm('Sei sicuro di voler accettare il preventivo?')) {
            try {
                const response = await axios.post(url, {
                                note_da_cliente: $('#note_da_cliente').val(),
                            });
                if (response.data.success) {
                    window.location.reload();
                } else {
                    window.alert(response.data.message);
                }
            } catch (error) {
                console.log(error);
                alert("Si è verificato un errore.", error);
            }
        }
    });


    $('.btnReject').on('click', async function () {
        const url = $(this).data('url');
        if (confirm('Sei sicuro di voler rifiutare il preventivo?')) {
            try {
                const response = await axios.post(url, {
                                note_da_cliente: $('#note_da_cliente').val(),
                            });
                if (response.data.success) {
                    window.location.reload();
                } else {
                    window.alert(response.data.message);
                }
            } catch (error) {
                console.log(error);
                alert("Si è verificato un errore.", error);
            }
        }
    });


});
