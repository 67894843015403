
$('#valTotale').on('click', function() {
    // nascondo la maschera riga
    $('#rigaContainer').addClass('hidden');

    $('#totaleContainer').removeClass('hidden');
})

$('#valRiga').on('click', function() {
    // nascondo la maschera riga
    $('#rigaContainer').removeClass('hidden');

    $('#totaleContainer').addClass('hidden');
})