$('body.preventivi.create').each(function(){
    const form = $('#preventiviCreateForm');
    form.on('submit', function(event){
        // form.find('input, select, '

        // event.preventDefault();
    });

    // in case of errors check if we need to reopen produttore card section
    if ($('#produttoreCard .is-invalid').length) {
        // $('#rpaddr1').trigger('click');
    }
});
